import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

const ContentPage = ({ data, location, ...props }) => {
    return (
        <SeoPage
            leadImage={data.img0.childImageSharp.fluid.src}
            location={location}
            title="Récupérer son permis après l'alcool au volant: étapes et coûts!"
            description="Vous avez été reconnu coupable de conduite avec les facultés affaiblies par l’alcool ? Nous vous expliquons comment récupérer votre permis de conduire !"
            fr={undefined}
            lawType="criminal"
            specialtyText="pour Alcool au volant">
            <p>
                Être reconnu coupable de conduite avec facultés affaiblies
                entraîne des conséquences majeures, dont la suspension ou la
                révocation du permis de conduire.{' '}
            </p>
            <p>
                Perdre ce droit peut compliquer le quotidien, notamment pour se
                rendre au travail ou gérer ses obligations personnelles.{' '}
            </p>
            <p>
                <strong>
                    Récupérer son permis après la période de sanction n’est pas
                    automatique et requiert plusieurs démarches. Il en va de
                    même pour l’obtention d’un permis restreint durant la
                    sanction.
                </strong>
            </p>
            <p>
                <Img
                    fluid={data.img0.childImageSharp.fluid}
                    title="Sanctions liées à la conduite avec les facultés affaiblies par l’alcool!"
                    alt=""
                />
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>
                                Dans cet article, nous vous détaillons :
                            </strong>
                        </p>
                        <ul>
                            <li>
                                <strong>
                                    Les conséquences d’une première condamnation
                                    pour conduite avec facultés affaiblies par
                                    l’alcool.
                                </strong>
                            </li>
                            <li>
                                <strong>
                                    Les étapes ainsi que les coûts liés à
                                    l’obtention d’un permis restreint.
                                </strong>
                            </li>
                            <li>
                                <strong>
                                    Les étapes ainsi que les coûts liés à la
                                    récupération d’un permis de conduire après
                                    la période de sanction.
                                </strong>
                            </li>
                        </ul>
                    </td>
                </tr>
            </table>
            <h2>
                La conduite avec facultés affaiblies par l’alcool est une
                infraction criminelle entraînant de nombreuses sanctions !
            </h2>
            <p>
                L'infraction de conduite avec facultés affaiblies par l'alcool,
                régie par l'article{' '}
                <Link to="https://laws-lois.justice.gc.ca/fra/lois/c-46/section-320.14.html">
                    320.14
                </Link>{' '}
                du <em>Code criminel,</em> peut être constatée dans deux cas :
            </p>
            <ol>
                <li>
                    Conduite avec des facultés affaiblies par l'alcool, les
                    drogues ou une combinaison des deux:
                </li>
            </ol>
            <p>
                La première situation survient lorsqu'un individu conduit un
                véhicule alors que ses capacités sont altérées par l'alcool, les
                drogues, ou une combinaison des deux.{' '}
            </p>
            <h4>
                Concentration d'alcool égale ou supérieure à 80 milligrammes par
                100 millilitres de sang dans les deux heures suivant la
                conduite:
            </h4>
            <p>
                La seconde situation survient lorsqu’une personne affiche une
                concentration d'alcool dans le sang égale ou supérieure à 80
                milligrammes par 100 millilitres de sang, et ce, dans les deux
                heures suivant la fin de la conduite.{' '}
            </p>
            <p>
                Toutefois, si l’alcool a été consommé uniquement après avoir
                cessé de conduire, le conducteur ne sera pas reconnu coupable.
            </p>
            <h3>
                <strong>
                    <strong>
                        Quelles sont les conséquences d’être reconnu coupable de
                        conduite avec les facultés affaiblies par l’alcool?
                    </strong>
                </strong>
            </h3>
            <p>
                Être reconnu coupable de conduite avec les facultés affaiblies
                par l'alcool entraîne plusieurs conséquences graves.
            </p>
            <p>
                {' '}
                Voici un aperçu des sanctions associées à cette infraction
                lorsqu'une personne est arrêtée et reconnue coupable{' '}
                <strong>pour la première fois :</strong>
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Conséquences</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Explication</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Suspension du permis de conduire</p>
                    </td>
                    <td>
                        <p>
                            Dès l'arrestation, une suspension administrative du
                            permis de conduire est imposée par la SAAQ pour une
                            période de <strong>90 jours</strong>.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            <strong>Révocation du permis de conduire</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            Après la déclaration de culpabilité, le permis de
                            conduire sera révoqué pour{' '}
                            <strong>une période minimale d'un an</strong>, mais
                            cette durée peut aller jusqu'à trois ans si le juge,
                            en fonction des circonstances, estime cela
                            nécessaire.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            <strong>Saisie du véhicule</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            Si l'alcoolémie est égale ou supérieure à 160 mg par
                            100 ml de sang, ou si le conducteur refuse de se
                            soumettre à un alcootest, la saisie du véhicule peut
                            être ordonnée.{' '}
                        </p>
                        <p>
                            Le véhicule sera confisqué pour une{' '}
                            <strong>période de 30 jours</strong>, avec des frais
                            de remorquage et d'entreposage.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            <strong>Peine d'emprisonnement</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            En fonction de la gravité de l'infraction, le
                            tribunal peut imposer une peine d'emprisonnement{' '}
                            <strong>pouvant aller jusqu'à 10 ans.</strong>
                        </p>
                        <p>
                            {' '}
                            Cependant, contrairement à une deuxième et troisième
                            condamnation pour cette infraction, aucune peine
                            minimale d'emprisonnement n'est prévue.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Dispositif antidémarrage</p>
                    </td>
                    <td>
                        <p>
                            Le tribunal peut ordonner l'installation d'un
                            dispositif antidémarrage éthylométrique, dont la
                            durée de son installation est à la discrétion du
                            tribunal.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Programmes de réduction du risque</p>
                    </td>
                    <td>
                        <p>
                            Le tribunal peut ordonner la participation au
                            Programme d'évaluation et de réduction du risque de
                            conduite avec les facultés affaiblies ainsi qu'au
                            programme Alcofrein.{' '}
                        </p>
                        <p>
                            Ces programmes visent à évaluer les conducteurs, à
                            les sensibiliser aux dangers de la conduite en état
                            d’ébriété et à promouvoir des comportements plus
                            sûrs.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            <strong>Amende</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            Le conducteur devra payer une{' '}
                            <strong>amende obligatoire</strong>, dont le montant
                            varie en fonction du taux d'alcool dans le sang :
                        </p>
                        <ul>
                            <li>
                                De 80 mg à 119 mg d'alcool par 100 ml de sang :
                                une amende de 1 000 $
                            </li>
                            <li>
                                De 120 mg à 159 mg d'alcool par 100 ml de sang :
                                une amende de 1 500 $
                            </li>
                            <li>
                                160 mg d'alcool par 100 ml de sang ou plus, ou
                                en cas de refus de se soumettre à un alcootest :
                                une amende de 2 000 $.
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            <strong>Inscription au casier judiciaire</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            Une condamnation pour conduite avec facultés
                            affaiblies par l’alcool sera inscrite au casier
                            judiciaire du contrevenant.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                <Img
                    fluid={data.img1.childImageSharp.fluid}
                    title="Les conséquences juridiques de l’alcool au volant !"
                    alt=""
                />
            </p>
            <h2>
                Comment récupérer son permis de conduire après une condamnation
                pour conduite avec les facultés affaiblies par l’alcool?
            </h2>
            <p>
                Après une condamnation pour conduite avec facultés affaiblies,
                il existe deux façons de récupérer son permis de conduire :
            </p>
            <ol>
                <li>
                    La première option consiste à{' '}
                    <strong>obtenir un permis restreint</strong>, permettant de
                    conduire pendant la période de sanction, mais sous des
                    restrictions strictes.
                </li>
                <li>
                    La deuxième option est{' '}
                    <strong>
                        d'attendre la fin de la période de sanctions, puis
                        d'entreprendre les démarches nécessaires pour récupérer
                        son permis de conduire auprès de la SAAQ
                    </strong>
                    . Dans ce cas, il sera nécessaire de remplir toutes les
                    formalités et de respecter les exigences définies par la
                    SAAQ.
                </li>
            </ol>
            <h3>Qu'est-ce qu'un permis restreint et comment l’obtenir?</h3>
            <p>
                Lorsque vous êtes reconnu coupable de conduite avec facultés
                affaiblies par l’alcool, la SAAQ révoque votre permis de
                conduire pour une période minimale d'un an. Cependant, il est
                possible, durant la période de sanction, de demander un permis
                restreint.{' '}
            </p>
            <p>
                Un permis restreint vous autorise à conduire uniquement des
                véhicules équipés d’un antidémarreur éthylométrique, un
                dispositif qui empêche le démarrage du véhicule si une quantité
                d’alcool est détectée dans votre souffle.
            </p>
            <p>
                <strong>
                    Un permis restreint pour alcool au volant est soumis à
                    plusieurs conditions :
                </strong>
            </p>
            <h4>
                <strong>
                    <strong>Admissibilité</strong>
                </strong>{' '}
            </h4>
            <p>
                Vous ne pouvez obtenir un permis restreint si vous avez d'autres
                sanctions sur votre dossier de conduite, si vous souffrez d'un
                problème de santé affectant votre capacité à conduire, ou si
                vous êtes titulaire d’un permis d’apprenti conducteur. De plus,
                un permis restreint ne peut être accordé si vous avez uniquement
                un permis de moto (classe 6).
            </p>
            <h4>
                <strong>
                    <strong>Restrictions à respecter</strong>
                </strong>{' '}
            </h4>
            <p>
                Pour conserver votre permis restreint, vous devrez vous
                conformer à des conditions strictes, telles que :
            </p>
            <ul>
                <li>
                    Conduire uniquement un véhicule équipé d’un antidémarreur
                    éthylométrique.
                </li>
                <li>
                    Conduire sans la moindre présence d'alcool dans votre
                    organisme.
                </li>
                <li>Respecter les instructions d’utilisation du dispositif.</li>
                <li>
                    Suivre la fréquence des visites d'entretien de
                    l'antidémarreur éthylométrique et de collecte des données,
                    conformément aux exigences.
                </li>
                <li>
                    Respecter les consignes affichées sur l’écran de l’appareil.
                </li>
                <li>
                    Ne pas tenter de contourner, modifier ou retirer
                    l’antidémarreur éthylométrique.
                </li>
                <li>
                    Assumer les frais d'installation (50 $ plus taxes) et de
                    location mensuelle de l'antidémarreur éthylométrique (61 $
                    plus taxes).
                </li>
            </ul>
            <h3>
                <strong>
                    <strong>
                        Les étapes pour obtenir un permis restreint:
                    </strong>
                </strong>
            </h3>
            <ol>
                <li>
                    <strong>Vérification de l’admissibilité</strong> : Commencez
                    par consulter votre dossier à la SAAQ pour vous assurer que
                    vous êtes éligible à un permis restreint.
                </li>
                <li>
                    <strong>Prendre rendez-vous</strong> : Ensuite, prenez
                    rendez-vous dans un centre de services de la SAAQ pour
                    demander votre permis restreint. Vous devrez payer les frais
                    associés à la délivrance du permis.
                </li>
                <li>
                    <strong>Antidémarreur éthylométrique</strong> : Une fois
                    votre permis récupéré, vous devrez prendre rendez-vous avec
                    un fournisseur autorisé d’antidémarreurs éthylométriques. Ce
                    dernier vous expliquera le processus et installera le
                    dispositif dans votre véhicule.
                </li>
                <li>
                    <strong>Installation du dispositif</strong> : Présentez-vous
                    au point de service de ce fournisseur pour signer l’entente
                    de service et procéder à l’installation de l’antidémarreur.
                </li>
            </ol>
            <p>
                <Img
                    fluid={data.img2.childImageSharp.fluid}
                    title="Qu'est-ce qu'un permis restreint et comment l’obtenir ?"
                    alt=""
                />
            </p>
            <p>
                Il est à noter que la contribution d’assurance exigée par la
                SAAQ pour un permis restreint s’élève à 201,17 $ en 2025.
            </p>
            <h4>
                <strong>
                    <strong>
                        Sanctions en cas de non-respect des conditions
                    </strong>
                </strong>
            </h4>
            <p>
                Si vous ne respectez pas ces conditions, plusieurs sanctions
                peuvent être appliquées, notamment :
            </p>
            <ul>
                <li>
                    La saisie immédiate de votre véhicule pour une période de 30
                    à 90 jours.
                </li>
                <li>Une amende pouvant atteindre entre 1 500 $ et 3 000 $.</li>
                <li>La révocation de votre permis restreint.</li>
            </ul>
            <h3>
                Récupérer son permis de conduire auprès de la SAAQ après la
                période de sanction :
            </h3>
            <p>
                Après la période de sanctions imposée, récupérer son permis de
                conduire n’est pas automatique. Le conducteur doit accomplir
                certaines formalités exigées par la SAAQ. Les modalités varient
                selon la gravité de l'infraction.{' '}
            </p>
            <p>
                En effet, le processus et les exigences pour un taux d'alcool
                dans le sang égal ou supérieur à 80 mg, mais inférieur à 160 mg
                par 100 ml, ainsi que pour la conduite avec facultés affaiblies
                par l'alcool, les drogues, ou une combinaison des deux, sont
                spécifiques et différents de ceux applicables lorsqu'un taux
                d’alcool est égal ou supérieur à 160 mg par 100 ml de sang.{' '}
            </p>
            <p>
                Dans tous les cas, des étapes doivent être suivies pour
                récupérer son permis de conduire auprès de la SAAQ.
            </p>
            <h4>
                Obtenir un permis de conduire après la période de sanction (pour
                un taux d’alcool inférieur à 160 mg/100 ml de sang, en cas de
                conduite avec facultés affaiblies par l’alcool, les drogues, ou
                une combinaison des deux):
            </h4>
            <p>
                <strong>Étape 1 : Réception de la lettre de la SAAQ</strong>
            </p>
            <p>
                Après le jugement du tribunal, vous recevrez une lettre de la
                SAAQ, vous informant de la révocation de votre permis et de la
                date à laquelle votre sanction prendra fin.{' '}
            </p>
            <p>
                Un an avant la fin de cette période d'interdiction, la SAAQ vous
                enverra une lettre supplémentaire pour vous rappeler de
                commencer le processus de récupération de votre permis,
                notamment en suivant une évaluation sommaire du Programme
                d'évaluation et de réduction du risque de conduite avec les
                capacités affaiblies.
            </p>
            <p>
                <strong>Étape 2: Évaluation sommaire</strong>
            </p>
            <p>
                Pour récupérer votre permis de conduire après la sanction, il
                vous faut avoir complété l’évaluation sommaire. Cette évaluation
                est un processus standardisé réalisé par un évaluateur agréé.
                Vous devrez vous inscrire et payer des frais de 351,00 $ (+
                taxes) pour passer cette évaluation.
            </p>
            <p>
                <Img
                    fluid={data.img3.childImageSharp.fluid}
                    title="Évaluation sommaire de la SAAQ!"
                    alt=""
                />
            </p>
            <p>
                Cette évaluation a pour objectif de déterminer si vous êtes en
                mesure de dissocier la consommation d'alcool, de drogues ou de
                médicaments, de la conduite en toute sécurité. Un rapport sera
                rédigé à la suite de l’évaluation, qui sera envoyé à la SAAQ
                pour prise de décision.
            </p>
            <h4>
                <strong>
                    <strong>La SAAQ accepte de délivrer un permis</strong>
                </strong>
            </h4>
            <p>
                La SAAQ prendra une décision sur la base du rapport d’évaluation
                sommaire, de votre historique de conduite et de votre état de
                santé. Si la décision est favorable, vous serez informé que vous
                avez droit d’obtenir un nouveau permis de conduire.{' '}
            </p>
            <p>
                Toutefois, vous devrez suivre le programme Alcofrein, d'une
                durée de 3 heures, au coût de 150,00 $, qui vise à vous
                sensibiliser aux conséquences de la conduite en état d'ivresse
                et à la manière de dissocier la consommation de la conduite.
            </p>
            <p>
                Une fois que vous aurez terminé le programme Alcofrein, vous
                devrez présenter l’attestation de réussite à un centre de
                services de la SAAQ afin d’obtenir votre permis de conduire. Ce
                programme est une condition préalable pour récupérer un permis
                après une première infraction de conduite avec les facultés
                affaiblies par l’alcool.
            </p>
            <h4>
                <strong>
                    <strong>Si la SAAQ refuse de délivrer un permis</strong>
                </strong>
            </h4>
            <p>
                Dans certains cas, l’évaluation sommaire peut indiquer que vous
                présentez un risque de récidive ou que vous ne parvenez pas à
                dissocier consommation et conduite. Dans ce cas, la SAAQ vous
                demandera de passer une évaluation complète du Programme
                d'évaluation et de réduction du risque de conduite avec les
                capacités affaiblies, au coût de 830,70 $ (+ taxes).{' '}
            </p>
            <p>
                <strong>Étape 3: Évaluation complète</strong>
            </p>
            <p>
                L’évaluation complète est beaucoup plus détaillée et peut durer
                de 7 à 9 mois. Elle comporte un plan d'encadrement et plusieurs
                rencontres obligatoires avec un professionnel. Cette évaluation
                approfondie inclut un examen médical que vous devrez faire
                remplir par votre médecin et renvoyer à la SAAQ.{' '}
            </p>
            <p>
                Le rapport qui en résulte sera utilisé par la SAAQ pour prendre
                une décision. Si vous êtes jugé apte à reprendre la conduite, la
                SAAQ pourra vous délivrer un permis conditionnel avec
                l’obligation de conduire un véhicule équipé d’un antidémarreur
                éthylométrique.
            </p>
            <p>
                <strong>
                    Étape 4 : Obtention d’un permis de conduire avec condition I
                </strong>
            </p>
            <p>
                Si vous êtes jugé apte, mais avec un risque faible de récidive,
                la SAAQ peut vous permettre de récupérer un permis conditionnel,
                accompagné de la condition I. Cela signifie que vous ne pourrez
                conduire que des véhicules équipés d’un antidémarreur
                éthylométrique.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img4.childImageSharp.fluid}
                    title="Obtention d’un permis de conduire avec condition I!"
                    alt=""
                />
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            La procédure de location et d'installation de
                            l'antidémarreur est identique à celle requise pour
                            un permis restreint lié à une condamnation pour
                            alcool au volant.
                        </p>
                    </td>
                </tr>
            </table>
            <h4>
                Obtenir un permis de conduire après la période de sanction (pour
                un taux d’alcool supérieur à 160 mg/100 ml de sang):
            </h4>
            <h4>
                <strong>
                    <strong>Étape 1 : Réception de la lettre de la SAAQ</strong>
                </strong>
            </h4>
            <p>
                Si vous avez été reconnu coupable pour un taux d'alcool
                supérieur à 160 mg/100 ml de sang, la SAAQ vous enverra une
                lettre confirmant la révocation de votre permis ainsi que la
                durée de l’interdiction.
            </p>
            <p>
                Un an avant la fin de cette période d'interdiction, la SAAQ vous
                enverra une lettre pour vous rappeler de commencer l’évaluation
                complète ou l’évaluation du maintien des acquis.
            </p>
            <p>
                <strong>
                    Étape 2 : Évaluation complète ou évaluation du maintien des
                    acquis
                </strong>
            </p>
            <p>
                Un an avant la fin de votre sanction, vous devrez entamer
                l’évaluation complète du{' '}
                <strong>
                    Programme d'évaluation et de réduction du risque de conduite
                    avec les capacités affaiblies
                </strong>
                , si vous ne l'avez pas déjà réalisée. Cependant, si une
                évaluation antérieure a été effectuée et jugée favorable par la
                SAAQ, vous ne serez pas tenu de la repasser.
            </p>
            <p>
                À la place, une{' '}
                <strong>évaluation du maintien des acquis</strong> (coût :
                409,50 $ + taxes) sera requise pour déterminer votre
                admissibilité à la récupération de votre permis de conduire.
                Cette évaluation, d’une durée de quelques heures, vise également
                à confirmer l’absence de risque de récidive.
            </p>
            <p>
                <strong>Étape 3 : Décision de la SAAQ</strong>
            </p>
            <p>
                Suite à l'évaluation, la SAAQ prendra une décision basée sur le
                rapport d'évaluation que vous aurez passé. Cette décision sera
                communiquée par un avis officiel. Si la décision est
                défavorable, vous recevrez une lettre de la SAAQ vous informant
                du rejet de votre demande. En cas de désaccord avec cette
                décision, vous avez la possibilité de la contester dans un délai
                de 60 jours.
            </p>
            <p>
                <strong>Si la décision est favorable</strong>, vous recevrez une
                lettre confirmant l'acceptation de votre{' '}
                <strong>permis, assorti de la condition X, </strong>vous
                obligeant à conduire un véhicule équipé d'un antidémarreur
                éthylométrique pendant 2 ans.
            </p>
            <p>
                Cependant, si votre permis a été révoqué depuis plus de 3 ans,
                vous devrez repasser certains examens de la SAAQ afin de
                démontrer que vous êtes apte à obtenir la classe de permis que
                vous souhaitez.{' '}
            </p>
            <p>
                Il est important de mentionner qu'une fois les conditions
                remplies, la SAAQ exige une contribution supplémentaire pour
                l'assurance afin de récupérer un permis. Cette contribution
                varie entre 420,00 $ et 560,00 $, en fonction de l'infraction.
            </p>
            <h2>
                Vous êtes accusé de conduite avec les facultés affaiblies par
                l’alcool? Consultez un avocat spécialisé en droit criminel !
            </h2>
            <p>
                Face à une accusation de conduite avec facultés affaiblies par
                l'alcool, les conséquences peuvent être lourdes, notamment la
                suspension et la révocation de votre permis de conduire. Dans
                cette situation, il est fortement recommandé de consulter un
                avocat spécialisé en droit criminel.{' '}
            </p>
            <p>
                Son expertise est indispensable pour limiter les impacts d’une
                condamnation et, dans certains cas, pour vous permettre de
                conserver votre permis. Un avocat expérimenté saura vous offrir
                une défense personnalisée et, si les circonstances le
                permettent, chercher à obtenir un acquittement.
            </p>
            <p>
                Si la condamnation est inévitable, il pourra vous accompagner
                pour obtenir un permis restreint, afin que vous puissiez
                conduire sous certaines conditions, ou vous aider à récupérer
                votre permis une fois la période de sanction terminée.
            </p>
            <p>
                <Img
                    fluid={data.img5.childImageSharp.fluid}
                    title="Avocat pour récupérer son permis après alcool au volant!"
                    alt=""
                />
            </p>
            <p>
                <strong>
                    Grâce à notre formulaire simple et gratuit, vous serez en
                    mesure de trouver rapidement un avocat spécialisé en droit
                    criminel !
                </strong>
            </p>
            <p>
                <strong>
                    Suite à votre demande, nous vous mettrons rapidement en
                    contact avec un avocat près de chez vous. Ce processus ne
                    nécessite aucun engagement de votre part !
                </strong>
            </p>
        </SeoPage>
    )
}

export default ContentPage

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "recuperer-permis-apres-alcool-volant/sanctions-conduite-facultes-affaiblies-alcool.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "recuperer-permis-apres-alcool-volant/consequences-juridiques-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "recuperer-permis-apres-alcool-volant/comment-obtenir-permis-restreint-quebec.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "recuperer-permis-apres-alcool-volant/evaluation-sommaire-alcool-volant-saaq.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img4: file(
            relativePath: {
                eq: "recuperer-permis-apres-alcool-volant/permis-conduire-condition-i.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img5: file(
            relativePath: {
                eq: "recuperer-permis-apres-alcool-volant/avocat-recuperer-permis-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
